var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card card-custom gutter-b"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"flex-grow-1"},[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('div',{staticClass:"d-flex align-items-center mr-3"},[(!_vm.isInitialLoading && _vm.reiseterminObj)?[_c('a',{staticClass:"d-flex align-items-center flex-wrap text-dark text-nowrap font-size-h5 font-weight-bold mr-3"},[_vm._v(" "+_vm._s(_vm.reiseterminObj.reiseterminkuerzel)+" ")]),_c('b-badge',{staticClass:"mr-4"},[_vm._v(_vm._s(_vm.getStateGerman(_vm.reiseterminObj?.state)))]),_c('b-badge',{staticClass:"mr-4"},[_vm._v(_vm._s(_vm.getInBlankAngelegt(_vm.reiseterminObj?.reiseterminSyncState)))]),_c('ReleaseprozessIcons',{staticClass:"mr-4",attrs:{"reiseterminObject":_vm.reiseterminObj}})]:_vm._e(),(_vm.isInitialLoading)?_c('b-skeleton',{attrs:{"height":"24px","width":"600px"}}):_vm._e(),(!_vm.isInitialLoading && _vm.reiseterminObj)?_c('div',{staticClass:"d-flex flex-wrap my-2"},[_c('a',{staticClass:"text-muted font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"},[_c('i',{staticClass:"flaticon-earth-globe mr-1"}),_vm._v(" "+_vm._s(_vm.$t('REISEN.REGIONS.' + _vm.reiseterminObj.reise.region))+" ")]),_c('a',{staticClass:"text-muted font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"},[_c('i',{staticClass:"flaticon-earth-globe mr-1"}),_vm._v(" "+_vm._s(_vm.$t('REISEN.COUNTRIES.' + _vm.reiseterminObj.reise.country))+" ")])]):_vm._e()],2),(_vm.reiseterminObj)?_c('div',{staticClass:"my-lg-0 my-1"},[(_vm.$route.name === 'reisetermine-view')?_c('b-button',{directives:[{name:"allowedRoles",rawName:"v-allowedRoles",value:([_vm.Role.ADMIN, _vm.Role.PM, _vm.Role.YIELD]),expression:"[Role.ADMIN, Role.PM, Role.YIELD]"}],staticClass:"btn btn-light-primary btn-icon btn-xl ml-2 mb-2 text-primary text-hover-white",attrs:{"aria-label":"bearbeiten","title":"Reise bearbeiten","href":'#/reisen/' +
                _vm.reiseterminObj.reiseterminkuerzel?.substring(0, 6) +
                '/reisestammdaten?reiseterminAbreisedatum=' +
                _vm.reiseterminObj.abreisedatum.substring(0, 10)}},[_c('i',{staticClass:"flaticon-edit icon-1x"})]):_vm._e(),(_vm.$route.name === 'reisetermin-view-releasepruefung')?_c('b-button',{directives:[{name:"allowedRoles",rawName:"v-allowedRoles",value:([_vm.Role.ADMIN, _vm.Role.PM, _vm.Role.YIELD, _vm.Role.DOM]),expression:"[Role.ADMIN, Role.PM, Role.YIELD, Role.DOM]"}],staticClass:"btn btn-light-primary btn-icon btn-xl ml-2 mb-2 text-primary text-hover-white",attrs:{"aria-label":"bearbeiten","title":"Reise bearbeiten","to":{
                name: 'reisen-edit-view',
                params: { reisekuerzel: _vm.reiseterminObj.reise.reisekuerzel },
              }}},[_c('i',{staticClass:"flaticon-edit icon-1x"})]):_vm._e(),_c('b-button',{staticClass:"btn btn-light-primary btn-icon btn-xl ml-2 mb-2 text-primary text-hover-white",attrs:{"aria-label":"reiseunterlagen","title":"Reiseunterlagen","href":'https://www.skr.de/reiseinfos/' +
                _vm.reiseterminObj?.reiseterminkuerzel.replaceAll('-', '').replaceAll('_', '') +
                '/L/',"target":"_blank"}},[_c('i',{staticClass:"far fa-list-alt fa-lg"})]),_c('b-button',{staticClass:"btn btn-light-primary btn-icon btn-xl ml-2 mb-2 text-primary text-hover-white",attrs:{"aria-label":"reisewiki","title":"Reisewiki","href":'https://skr.de/+' + _vm.reiseterminObj.reise.reisekuerzel,"target":"_blank"}},[_c('i',{staticClass:"fab fa-wikipedia-w"})]),(_vm.kontaktdatenlisteLink)?_c('b-button',{staticClass:"btn btn-light-primary btn-icon btn-xl ml-2 mb-2 text-primary text-hover-white",attrs:{"aria-label":"reisewiki","title":"Kontaktdatenliste","href":_vm.kontaktdatenlisteLink,"target":"_blank"}},[_c('i',{staticClass:"flaticon2-user-outline-symbol"})]):_vm._e()],1):_vm._e()])])]),(_vm.$route.name === 'reisetermine-view' && _vm.isTerminNach24)?_c('ReiseterminViewReisetermindatenDetail',{attrs:{"reiseterminObj":_vm.reiseterminObj}}):_vm._e(),(_vm.$route.name === 'reisetermin-view-releasepruefung' && _vm.isTerminNach24)?_c('ReiseterminViewReisetermindatenReleasepruefung',{attrs:{"reiseterminObj":_vm.reiseterminObj}}):_vm._e(),(_vm.isTerminIn24 && _vm.$route.name === 'reisetermine-view')?_c('ReiseterminViewDetailHeader',{attrs:{"isInitialLoading":_vm.isInitialLoading,"reiseterminObj":_vm.reiseterminObj},on:{"aufgabeBearbeitet":_vm.onAufgabeBearbeitet}}):_vm._e(),(_vm.isTerminIn24 && _vm.$route.name === 'reisetermin-view-releasepruefung')?_c('ReiseterminViewDetailHeaderReleasepruefung',{attrs:{"isInitialLoading":_vm.isInitialLoading,"reiseterminObj":_vm.reiseterminObj},on:{"aufgabeBearbeitet":_vm.onAufgabeBearbeitet}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }