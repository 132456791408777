<template>
  <div class="d-flex align-items-center flex-lg-fill mr-5 my-1">
    <span class="mr-4">
      <i class="flaticon-chat-1 icon-2x text-muted font-weight-bold"></i>
    </span>
    <div class="d-flex flex-column w-100">
      <span class="d-flex align-items-center text-dark-75 font-weight-bolder font-size-sm">
        {{ $t('COLUMNNAMES.Besonderheiten') }}
        <SavedContent :isSaved="isSaved"></SavedContent>
      </span>
      <b-form-textarea
        v-model="text"
        placeholder="Hier Besonderheiten eintragen..."
        @input="$emit('onKeyUp', $event, text)"
        max-rows="4"
        style="padding: 8px"
      />
    </div>
  </div>
</template>

<script>
import SavedContent from '@/components/common/saved-content.vue';

export default {
  components: { SavedContent },
  props: {
    isSaved: { type: Boolean },
    value: { type: String },
  },
  data() {
    return {
      text: null,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.text = newValue;
        }
      },
    },
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
:deep(.mce-edit-focus) {
  background: #ffffff;
}
:deep(p) {
  margin-bottom: 0;
}
.mce-content-body {
  max-height: 38px;
  overflow-y: scroll;
}
*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
*::-webkit-scrollbar-track {
  border-radius: 25px;
  background: rgba($color: #707070, $alpha: 0.1);
}
*::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 25px;
  border: 5px solid gray;
}
</style>
