<template>
  <ReiseterminViewBesonderheiten
    :value="besonderheiten"
    :isSaved="isSaved"
    @onKeyUp="onKeyUp"
  ></ReiseterminViewBesonderheiten>
</template>

<script>
import ReiseterminViewBesonderheiten from '@/components/produkte/reisetermine/besonderheiten.vue';
import apiService from '@/core/common/services/api.service';
import { SET_REISETERMIN_RELOAD_NEEDED } from '@/core/produkte/reisetermine/stores/workflow-manager-reisetermin.module.js';

export default {
  components: { ReiseterminViewBesonderheiten },
  props: {
    reisekuerzel: { type: String },
    startdatum: { type: String },
    besonderheiten: { type: String },
  },
  data() {
    return {
      value: null,
      isSaved: true,
      isLoading: true,
      inputDebounceValue: 300,
    };
  },
  methods: {
    saveBesonderheiten(besonderheiten, reisekuerzel, startdatum) {
      apiService
        .put(`Reise/${reisekuerzel}/Reisetermin/${startdatum}/`, {
          reisekuerzel: reisekuerzel,
          startdatum: startdatum,
          besonderheiten: besonderheiten,
        })
        .then(() => {
          this.isSaved = true;
          this.$store.commit(SET_REISETERMIN_RELOAD_NEEDED, true);
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onKeyUp($event, value) {
      clearTimeout(this.debounceTimeoutId);
      this.debounceTimeoutId = setTimeout(() => {
        this.isSaved = false;
        this.isLoading = true;
        this.saveBesonderheiten(value, this.reisekuerzel, this.startdatum);
      }, this.inputDebounceValue);
    },
  },
};
</script>
